<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-12">
          <div class="jumbotron" v-if="!loading">
            <h1 class="display-4 text-center">{{ client.name }}</h1>
            <div class="row">
              <div class="col-12 col-md-4 text-center">
                <p style="font-size: 20px">
                  <i class="fa fa-phone"></i> {{ client.phone }}
                </p>
              </div>
              <div class="col-12 col-md-4 text-center">
                <p style="font-size: 20px">
                  <i class="fa fa-envelope"></i> {{ client.email }}
                </p>
              </div>
              <div class="col-12 col-md-4 text-center">
                <p style="font-size: 20px">
                  <i class="fa fa-map"></i> {{ client.location }}
                </p>
              </div>
            </div>
            <hr class="my-4" />

            <div class="text-center">
              <b-button v-b-toggle.collapse-1 variant="primary" class=""
                ><i class="fa fa-info-circle"></i
              ></b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <div class="row">
                  <div class="col-12 col-md-4 text-center">
                    <div class="card mb-1 shadow">
                      <div class="card-header text-success">Sponsors</div>
                      <div class="card-body" style="padding: 0px">
                        <h3 class="card-title">
                          {{ client.users.length.toLocaleString("en") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div class="card mb-1 shadow">
                      <div class="card-header text-success">Patients</div>
                      <div class="card-body" style="padding: 0px">
                        <h3 class="card-title">
                          {{ client.patients_count.toLocaleString("en") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div class="card mb-1 shadow">
                      <div class="card-header text-success">
                        Total Amount Paid
                      </div>
                      <div class="card-body" style="padding: 0px">
                        <h3 class="card-title">
                          {{ formatMoney(client.total_amount_paid) || 0 }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-md-4 text-center">
                    <div class="card mb-1 shadow">
                      <div class="card-header text-success">Total Visits</div>
                      <div class="card-body" style="padding: 0px">
                        <h3 class="card-title">
                          {{ totalVisits.toLocaleString("en") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div class="card mb-1 shadow">
                      <div class="card-header text-success">
                        Executed Visits
                      </div>
                      <div class="card-body" style="padding: 0px">
                        <h3 class="card-title">
                          {{ client.visits_count.toLocaleString("en") }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 text-center">
                    <div class="card mb-1 shadow">
                      <div class="card-header text-success">Pending Visits</div>
                      <div class="card-body" style="padding: 0px">
                        <h3 class="card-title">
                          {{ client.pending_visits.toLocaleString("en") || 0 }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
        </div>

        <div class="col-12 layout-spacing">
          <ul
            class="nav nav-pills mb-3 mt-3 nav-fill"
            id="justify-pills-tab"
            role="tablist"
            v-if="!loading"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                id="justify-pills-home-tab"
                data-toggle="pill"
                href="#justify-pills-home"
                role="tab"
                aria-controls="justify-pills-home"
                aria-selected="true"
                >Members</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="justify-pills-patient-tab"
                data-toggle="pill"
                href="#justify-pills-patient"
                role="tab"
                aria-controls="justify-pills-patient"
                aria-selected="true"
                >Patients</a
              >
            </li>
            <li class="nav-item" v-if="client.report_ids.length > 0">
              <a
                class="nav-link"
                id="justify-pills-profile-tab"
                data-toggle="pill"
                href="#justify-pills-profile"
                role="tab"
                aria-controls="justify-pills-profile"
                aria-selected="false"
                >Visit Records</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="justify-pills-contact-tab"
                data-toggle="pill"
                href="#justify-pills-contact"
                role="tab"
                aria-controls="justify-pills-contact"
                aria-selected="false"
                >Summary</a
              >
            </li>
          </ul>

          <div class="tab-content" id="justify-pills-tabContent">
            <!-- first tab -->
            <div
              class="tab-pane fade show active"
              id="justify-pills-home"
              role="tabpanel"
              aria-labelledby="justify-pills-home-tab"
            >
              <div class="bg-white">
                <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                  <div class="widget-content widget-content-area br-6">
                    <div v-if="loading">
                      <b-skeleton-table
                        :rows="10"
                        :columns="5"
                        :table-props="{ bordered: true, striped: true }"
                      ></b-skeleton-table>
                    </div>

                    <div v-else class="table-responsive mb-4 mt-4">
                      <table
                        id="corporate_members"
                        class="table table-hover non-hover"
                        style="width: 100%"
                      >
                        <thead>
                          <tr>
                            <th>Sponsor Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Location</th>
                            <th>Elderly</th>
                            <th>Visits</th>
                            <th>Joined On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="user in users" :key="user.id">
                            <td>{{ user.name }}</td>
                            <td>{{ user.email }}</td>
                            <td>{{ user.phone }}</td>
                            <td>
                              {{ user.address }} {{ user.state }}
                              {{ user.country }}
                            </td>
                            <td>{{ user.patients.length }}</td>
                            <td>{{ getVisitsCount(user.patients) }}</td>
                            <td>{{ formatDate(user.created_at) }}</td>
                            <td>
                              <button
                                @click.prevent="showClientInfo(user.patients)"
                                class="btn btn-sm btn-success"
                              >
                                <i class="fa fa-info-circle"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- second tab -->
            <div
              class="tab-pane fade"
              id="justify-pills-patient"
              role="tabpanel"
              aria-labelledby="justify-pills-patient-tab"
            >
              <div class="bg-white">
                <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                  <div class="widget-content widget-content-area br-6">
                    <div class="text-right mb-n3">
                      <b-button
                        v-if="!loadingPatients"
                        v-b-toggle.sidebar-footer
                        >Upcoming <i class="fa fa-calendar"></i>
                      </b-button>

                      <b-sidebar
                        width="100vw"
                        id="sidebar-footer"
                        aria-label="Sidebar with custom footer"
                        no-header
                        right
                        shadow
                        backdrop-variant="dark"
                        backdrop
                      >
                        <template #footer="{ hide }">
                          <div
                            class="d-flex bg-dark text-light align-items-center px-3 py-2"
                          >
                            <strong class="mr-auto">
                              <div class="form-group">
                                <select
                                  class="form-control"
                                  v-model="visitSearch"
                                  id="visitSearch"
                                  @change="sortByTimeRange()"
                                >
                                  <option value="thisweek">This Week</option>
                                  <option value="nextweek">Next Week</option>
                                  <option value="lastweek">Last Week</option>
                                  <option value="thismonth">This Month</option>
                                  <option value="nextmonth">Next Month</option>
                                  <option value="lastmonth">Last Month</option>
                                  <option value="custom">Custom</option>
                                </select>
                              </div>
                              <div class="form-group" v-if="showCustomPicker">
                                <div class="input-group">
                                  <flat-pickr
                                    v-model="date"
                                    :config="config"
                                    class="form-control"
                                    placeholder="Select date"
                                    @on-close="handlePicker()"
                                    name="date"
                                  >
                                  </flat-pickr>
                                </div>
                              </div>
                            </strong>
                            <b-button size="sm" @click="hide">Close</b-button>
                          </div>
                        </template>
                        <div class="px-3 py-2">
                          <div v-if="loadingUpcoming">
                            <b-skeleton-table
                              :rows="10"
                              :columns="5"
                              :table-props="{ bordered: true, striped: true }"
                            ></b-skeleton-table>
                          </div>

                          <div v-else class="table-responsive mb-4 mt-4">
                            <table
                              id="corporate_members_patients_filtered"
                              class="table table-hover non-hover"
                              style="width: 100%"
                            >
                              <thead>
                                <tr>
                                  <th>Elderly Name</th>
                                  <th>Email</th>
                                  <th>Phone</th>
                                  <th>Gender</th>
                                  <th>Location</th>
                                  <th>Service</th>
                                  <th>Visits Left</th>
                                  <th>Visits Done</th>
                                  <th>Last Visit Date</th>
                                  <th>Next Visit Date</th>
                                  <th>Last Remark (Client Services)</th>
                                  <th>Elderly Response (Client Services)</th>
                                  <th>Action to be taken (Client Services)</th>
                                  <th>Last Remark (Medical Services)</th>
                                  <th>Elderly Response (Medical Services)</th>
                                  <th>Action to be taken (Medical Services)</th>
                                  <th>Added On</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="patient in filteredUpcomings"
                                  :key="patient.id"
                                >
                                  <td>
                                    {{ patient.firstname }}
                                    {{ patient.lastname }}
                                  </td>
                                  <td>{{ patient.email }}</td>
                                  <td>{{ patient.phone }}</td>
                                  <td>{{ patient.sex }}</td>
                                  <td>
                                    {{ patient.address }} {{ patient.city }}
                                    {{ patient.state }}
                                  </td>
                                  <td>
                                    <span v-if="patient.last_sub != null">
                                      {{
                                        patient.last_sub.service.service_name ||
                                        ""
                                      }}
                                    </span>
                                  </td>
                                  <td>{{ patient.visit_count }}</td>
                                  <td>
                                    <span
                                      v-if="
                                        patient.visits == null ||
                                        patient.visits.length == 0
                                      "
                                      >0</span
                                    >
                                    <span v-else>{{
                                      patient.visits.length
                                    }}</span>
                                  </td>
                                  <td>
                                    <span v-if="patient.last_visit == null"
                                      >None</span
                                    >
                                    <span v-else>{{
                                      formatDate(
                                        patient.last_visit.appointment_date
                                      )
                                    }}</span>
                                  </td>
                                  <td>
                                    <span v-if="patient.next_visit == null"
                                      >None</span
                                    >
                                    <span v-else>{{
                                      formatDate(patient.next_visit)
                                    }}</span>
                                  </td>

                                  <td>
                                    {{ patient.last_report_client_services }}
                                  </td>
                                  <td>
                                    {{
                                      patient.elderly_response_client_services
                                    }}
                                  </td>
                                  <td>
                                    {{
                                      patient.action_to_be_taken_client_services
                                    }}
                                  </td>

                                  <td>
                                    {{ patient.last_report_med_services }}
                                  </td>
                                  <td>
                                    {{ patient.elderly_response_med_services }}
                                  </td>
                                  <td>
                                    {{
                                      patient.action_to_be_taken_med_services
                                    }}
                                  </td>

                                  <td>{{ formatDate(patient.created_at) }}</td>
                                  <td>
                                    <button
                                      @click.prevent="
                                        editPatientRemark(patient)
                                      "
                                      class="btn mx-1 btn-xs btn-outline-warning"
                                    >
                                      <i class="fa fa-edit"></i>
                                    </button>

                                    <button
                                      @click.prevent="
                                        resendPatientAppointmentReminder(
                                          patient
                                        )
                                      "
                                      class="btn mx-1 btn-xs btn-outline-success"
                                      v-if="
                                        patient.next_visit != null &&
                                        patient.doctor != null &&
                                        visitSearch == 'thisweek'
                                      "
                                    >
                                      <i class="fa fa-paper-plane"></i>
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </b-sidebar>
                    </div>

                    <div v-if="loadingPatients">
                      <b-skeleton-table
                        :rows="10"
                        :columns="5"
                        :table-props="{ bordered: true, striped: true }"
                      ></b-skeleton-table>
                    </div>

                    <div v-else class="table-responsive mb-4 mt-4">
                      <table
                        id="corporate_members_patients"
                        class="table table-hover non-hover"
                        style="width: 100%"
                      >
                        <thead>
                          <tr>
                            <th>Elderly Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Gender</th>
                            <th>Location</th>
                            <th>Service</th>
                            <th>Visits Left</th>
                            <th>Visits Done</th>
                            <th>Last Visit Date</th>
                            <th>Next Visit Date</th>
                            <th>Last Remark (Client Services)</th>
                            <th>Elderly Response (Client Services)</th>
                            <th>Action to be taken (Client Services)</th>
                            <th>Last Remark (Medical Services)</th>
                            <th>Elderly Response (Medical Services)</th>
                            <th>Action to be taken (Medical Services)</th>
                            <th>Added On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="patient in patients" :key="patient.id">
                            <td>
                              {{ patient.firstname }} {{ patient.lastname }}
                            </td>
                            <td>{{ patient.email }}</td>
                            <td>{{ patient.phone }}</td>
                            <td>{{ patient.sex }}</td>
                            <td>
                              {{ patient.address }} {{ patient.city }}
                              {{ patient.state }}
                            </td>
                            <td>
                              <span v-if="patient.last_sub != null">
                                {{
                                  patient.last_sub.service.service_name || ""
                                }}
                              </span>
                            </td>
                            <td>{{ patient.visit_count }}</td>
                            <td>
                              <span
                                v-if="
                                  patient.visits == null ||
                                  patient.visits.length == 0
                                "
                                >0</span
                              >
                              <span v-else>{{ patient.visits.length }}</span>
                            </td>
                            <td>
                              <span v-if="patient.last_visit == null"
                                >None</span
                              >
                              <span v-else>{{
                                formatDate(patient.last_visit.appointment_date)
                              }}</span>
                            </td>
                            <td>
                              <span v-if="patient.next_visit == null"
                                >None</span
                              >
                              <span v-else>{{
                                formatDate(patient.next_visit)
                              }}</span>
                            </td>

                            <td>{{ patient.last_report_client_services }}</td>
                            <td>
                              {{ patient.elderly_response_client_services }}
                            </td>
                            <td>
                              {{ patient.action_to_be_taken_client_services }}
                            </td>

                            <td>{{ patient.last_report_med_services }}</td>
                            <td>{{ patient.elderly_response_med_services }}</td>
                            <td>
                              {{ patient.action_to_be_taken_med_services }}
                            </td>

                            <td>{{ formatDate(patient.created_at) }}</td>
                            <td>
                              <button
                                @click.prevent="editPatientRemark(patient)"
                                class="btn btn-xs btn-outline-warning"
                              >
                                <i class="fa fa-edit"></i>
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- third tab -->
            <div
              class="tab-pane fade"
              id="justify-pills-profile"
              role="tabpanel"
              aria-labelledby="justify-pills-profile-tab"
            >
              <div class="bg-white">
                <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                  <div class="widget-content widget-content-area br-6">
                    <div v-if="loadingVisitReports">
                      <b-skeleton-table
                        :rows="10"
                        :columns="5"
                        :table-props="{ bordered: true, striped: true }"
                      ></b-skeleton-table>
                    </div>

                    <div v-else class="table-responsive mb-4 mt-4">
                      <table
                        id="corporatemonthtlyvisitreports"
                        class="table table-hover non-hover"
                        style="width: 100%"
                        v-if="client.report_ids.length > 0"
                      >
                        <thead>
                          <tr>
                            <th>Doctor</th>
                            <th>Appointment Date</th>
                            <th>Next Date</th>
                            <th>Elderly</th>
                            <th>Elderly Phone</th>
                            <th>Sponsor</th>
                            <th>Sponsor Phone</th>
                            <th>Visit Status</th>
                            <th>Report Status</th>
                            <th>Visit Cycle</th>
                            <th>Elderly Remarks</th>
                            <th>Sponsor Remarks</th>
                            <th>Report</th>
                            <th>Med Services</th>
                            <th>Management Remark</th>
                            <th>Created On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="report in reports" :key="report.id">
                            <td>{{ report.doctor_name }}</td>
                            <td>{{ formatDate(report.appointment_date) }}</td>
                            <td>
                              <span v-if="report.next_visit == null">
                                {{ report.next_visit }}
                              </span>
                              <span v-else>
                                {{ formatDate(report.next_visit) }}
                              </span>
                            </td>
                            <td>
                              {{ report.elderly_name }}
                            </td>
                            <td>{{ report.elderly_phone }}</td>
                            <td>{{ report.sponsor_name }}</td>
                            <td>{{ report.sponsor_phone }}</td>
                            <td>
                              <span
                                class="shadow-none btn btn-success btn-sm"
                                v-if="report.status == 0"
                                >Completed</span
                              >
                              <span
                                class="shadow-none btn btn-dark btn-sm"
                                v-if="report.status == 1"
                                >Not Checked In</span
                              >
                              <span
                                class="shadow-none btn btn-warning btn-sm"
                                v-if="report.status == 2"
                                >Doctor Rescheduled</span
                              >
                              <span
                                class="shadow-none btn btn-primary btn-sm"
                                v-if="report.status == 3"
                                >Patient Rescheduled</span
                              >
                              <span
                                class="shadow-none btn btn-success btn-sm"
                                v-if="report.status == 4"
                                >Completed(couple)</span
                              >
                            </td>
                            <td>
                              <span
                                class="shadow-none btn btn-success btn-sm"
                                v-if="report.check == 3 || report.check == 4"
                                >Report Submitted</span
                              >
                              <span
                                class="shadow-none btn btn-danger btn-sm"
                                v-else
                                >Report Not Ready</span
                              >
                            </td>
                            <td>
                              <button
                                v-if="report.visit_cycle == 1"
                                class="btn btn-success"
                              >
                                Completed
                              </button>
                              <button v-else class="btn btn-danger">
                                Incomplete
                              </button>
                            </td>
                            <td>{{ report.elderly_remark }}</td>

                            <td>{{ report.sponsor_remark }}</td>
                            <td>{{ report.report }}</td>
                            <td>{{ report.med_services_remark }}</td>

                            <td>{{ report.management_remark }}</td>

                            <td>{{ formatDate(report.created_at) }}</td>
                            <td>
                              <div class="btn-group">
                                <button
                                  type="button"
                                  class="btn btn-success btn-sm"
                                  @click="viewReport(report)"
                                >
                                  <i class="fa fa-eye"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-warning btn-sm"
                                  @click="editReport(report)"
                                >
                                  <i class="fa fa-edit"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- fourth tab -->
            <div
              class="tab-pane fade"
              id="justify-pills-contact"
              role="tabpanel"
              aria-labelledby="justify-pills-contact-tab"
            >
              <div v-if="loadingSeries">
                <b-skeleton-table
                  :rows="10"
                  :columns="5"
                  :table-props="{ bordered: true, striped: true }"
                ></b-skeleton-table>
              </div>
              <div class="bg-white" v-else>
                <div class="text-left">
                  <button
                    class="btn m-1 btn-success"
                    :disabled="disablePrevSeriesItemButton"
                    @click.prevent="prevSeriesItems()"
                  >
                    <i class="fa fa-arrow-left"></i>
                  </button>
                  <button
                    class="btn m-1 btn-success"
                    :disabled="disableNextSeriesItemButton"
                    @click.prevent="nextSeriesItems()"
                  >
                    <i class="fa fa-arrow-right"></i>
                  </button>
                </div>
                <div id="chart">
                  <apexchart
                    type="heatmap"
                    height="500px"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- View Client Member Info  -->
    <div
      class="modal fade"
      id="viewClientModal"
      role="dialog"
      aria-labelledby="viewClientModalTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="mr-auto">
              <small class="modal-title" id="viewClientLabel"
                >Elderly Records</small
              >
            </div>
          </div>
          <div class="modal-body">
            <b-card>
              <div
                class="row mb-5"
                v-for="patient in clientMemberToShow"
                :key="patient.id"
              >
                <div class="col-12 col-md-6">
                  <ul class="list-group">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Patient Name:
                      <strong
                        >{{ patient.firstname }} {{ patient.lastname }}
                      </strong>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Email: <strong> {{ patient.email }} </strong>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center"
                    >
                      Visits Left: <strong> {{ patient.visit_count }} </strong>
                    </li>
                  </ul>
                </div>
                <div class="col-12 col-md-6">
                  <ul class="list-group">
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center disabled"
                    >
                      Phone: <strong>{{ patient.phone }}</strong>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center disabled"
                    >
                      Address: <strong>{{ patient.address }}</strong>
                    </li>
                    <li
                      class="list-group-item d-flex justify-content-between align-items-center disabled"
                    >
                      Emergency Contact:
                      <strong>{{ patient.emergency_phone }}</strong>
                    </li>
                  </ul>
                </div>

                <div class="col-12 mt-2">
                  <div class="table-responsive">
                    <table class="table table-bordered mb-4">
                      <thead>
                        <tr>
                          <th>Doctor</th>
                          <th>Appointment Date</th>
                          <th>Next Date</th>
                          <th>Visit Status</th>
                          <th>Report Status</th>
                          <th>Plan</th>
                          <th>Amount</th>
                          <th>Created on</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="visit in patient.visits" :key="visit.id">
                          <td>
                            {{ visit.doctor.firstname || "" }}
                            {{ visit.doctor.lastname || "" }}
                          </td>
                          <td>
                            <span
                              v-if="
                                visit.appointment_date != null &&
                                visit.appointment_date != ''
                              "
                              >{{ formatDate(visit.appointment_date) }}</span
                            >
                          </td>
                          <td>
                            <span
                              v-if="
                                visit.next_visit != null &&
                                visit.next_visit != ''
                              "
                              >{{ formatDate(visit.next_visit) }}</span
                            >
                          </td>
                          <td>
                            <span
                              class="shadow-none btn btn-success btn-sm"
                              v-if="visit.status == 0"
                              >Completed</span
                            >
                            <span
                              class="shadow-none btn btn-dark btn-sm"
                              v-if="visit.status == 1"
                              >Not Checked In</span
                            >
                            <span
                              class="shadow-none btn btn-warning btn-sm"
                              v-if="visit.status == 2"
                              >Doctor Rescheduled</span
                            >
                            <span
                              class="shadow-none btn btn-primary btn-sm"
                              v-if="visit.status == 3"
                              >Patient Rescheduled</span
                            >
                            <span
                              class="shadow-none btn btn-success btn-sm"
                              v-if="visit.status == 4"
                              >Completed(couple)</span
                            >
                          </td>
                          <td>
                            <span
                              class="shadow-none btn btn-success btn-sm"
                              v-if="visit.check == 3 || visit.check == 4"
                              >Report Submitted</span
                            >
                            <span
                              class="shadow-none btn btn-danger btn-sm"
                              v-else
                              >Report Not Ready</span
                            >
                          </td>
                          <td>
                            <span v-if="patient.last_sub != null">
                              {{ patient.last_sub.service.service_name || "" }}
                            </span>
                          </td>
                          <td>
                            <span v-if="patient.last_sub != null">
                              {{ patient.last_sub.pay_amount || "" }}
                            </span>
                          </td>
                          <td>{{ formatDate(visit.created_at) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <hr class="my-5" style="border-top: 1px solid grey" />
                </div>
              </div>
            </b-card>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- View Report Modal  -->
    <div
      class="modal fade"
      id="viewReportModal"
      role="dialog"
      aria-labelledby="viewReportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" id="viewReportModalContent">
          <div class="modal-header">
            <div class="mr-auto">
              <small class="modal-title" id="viewReportModalLabel"
                >Visit Report for Elderly {{ reportToView.elderly_name }} on
                {{ formatFancyDate(reportToView.appointment_date) }}</small
              >
            </div>
            <div class="ml-auto">
              <span
                class="shadow-none btn btn-success btn-sm"
                v-if="reportToView.status == 0"
                >Completed</span
              >
              <span
                class="shadow-none btn btn-dark btn-sm"
                v-if="reportToView.status == 1"
                >Not Checked In</span
              >
              <span
                class="shadow-none btn btn-warning btn-sm"
                v-if="reportToView.status == 2"
                >Doctor Rescheduled</span
              >
              <span
                class="shadow-none btn btn-primary btn-sm"
                v-if="reportToView.status == 3"
                >Patient Rescheduled</span
              >
              <span
                class="shadow-none btn btn-success btn-sm"
                v-if="reportToView.status == 4"
                >Completed(couple)</span
              >
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 col-lg-3 col-12 d-flex d-lg-block">
                <div class="card component-card_1">
                  <div class="card-body text-center">
                    <p class="small">
                      <span class="mg-b-0 small"> Doctor Name:</span> <br />
                      <strong>{{ reportToView.doctor_name }}</strong>
                    </p>
                    <p class="small">
                      <span class="mg-b-0 small"> Email:</span> <br />
                      <strong>{{ reportToView.doctor_email }}</strong>
                    </p>
                    <p class="small">
                      <span class="mg-b-0 small"> Phone:</span> <br />
                      <strong>{{ reportToView.doctor_phone }}</strong>
                    </p>
                  </div>
                </div>

                <div class="card component-card_1">
                  <div class="card-body text-center">
                    <p class="small">
                      <span class="mg-b-0 small"> Sponsor Name:</span> <br />
                      <strong>{{ reportToView.sponsor_name }}</strong>
                    </p>
                    <p class="small">
                      <span class="mg-b-0 small"> Email:</span> <br />
                      <strong>{{ reportToView.sponsor_email }}</strong>
                    </p>
                    <p class="small">
                      <span class="mg-b-0 small"> Phone:</span> <br />
                      <strong>{{ reportToView.sponsor_phone }}</strong>
                    </p>
                  </div>
                </div>

                <div class="card component-card_1">
                  <div class="card-body text-center">
                    <p class="small">
                      <span class="mg-b-0 small"> Elderly Name:</span> <br />
                      <strong>{{ reportToView.elderly_name }}</strong>
                    </p>
                    <p class="small">
                      <span class="mg-b-0 small"> Phone:</span> <br />
                      <strong>{{ reportToView.elderly_phone }}</strong>
                    </p>
                    <p class="small">
                      <span class="mg-b-0 small"> Visits Left:</span> <br />
                      <strong
                        ><span class="btn btn-success">{{
                          reportToView.visit_count
                        }}</span></strong
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-12 col-lg-9 col-12">
                <ul class="list-group">
                  <li class="list-group-item">
                    <p class="mg-b-0 small">Appointment Date:</p>
                    <h6>
                      {{ formatFancyDate(reportToView.appointment_date) }}
                    </h6>
                  </li>
                  <li class="list-group-item">
                    <p class="mg-b-0 small">Next Visit:</p>
                    <h6>{{ formatFancyDate(reportToView.next_visit) }}</h6>
                  </li>
                  <li class="list-group-item">
                    <p class="mg-b-0 small">Report:</p>
                    <h6>{{ reportToView.report }}</h6>
                  </li>

                  <li class="list-group-item">
                    <p class="mg-b-0 small">Elderly Remark:</p>
                    <h6>{{ reportToView.elderly_remark }}</h6>
                  </li>

                  <li class="list-group-item">
                    <p class="mg-b-0 small">Sponsor Remark:</p>
                    <h6>{{ reportToView.sponsor_remark }}</h6>
                  </li>
                  <li class="list-group-item">
                    <p class="mg-b-0 small">Medical Services Remark:</p>
                    <h6>{{ reportToView.med_services_remark }}</h6>
                  </li>
                  <li class="list-group-item">
                    <p class="mg-b-0 small">Management Remark:</p>
                    <h6>{{ reportToView.management_remark }}</h6>
                  </li>
                  <li class="list-group-item">
                    <p class="mg-b-0 small">Additional Remark:</p>
                    <h6>{{ reportToView.additional_remark }}</h6>
                  </li>
                </ul>
              </div>

              <div class="col-12">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 variant="info"
                      >View Full Visit Report</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <div class="row">
                        <div class="col-12 col-md-6">
                          <ul class="list-group">
                            <li class="list-group-item">
                              <p class="mg-b-0 small">Abdomen:</p>
                              <h6>{{ reportToView.abdomen }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Additional Remark:</p>
                              <h6>{{ reportToView.additional_remark }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Admission Required:</p>
                              <h6>{{ reportToView.admission_required }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Agreement:</p>
                              <h6>{{ reportToView.agreement }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Appointment Date:</p>
                              <h6>
                                {{
                                  formatFancyDate(reportToView.appointment_date)
                                }}
                              </h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Assessment:</p>
                              <h6>{{ reportToView.assessment }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Blood Pressure:</p>
                              <h6>{{ reportToView.blood_pressure }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">
                                Blood Pressure Narration:
                              </p>
                              <h6>
                                {{ reportToView.blood_pressure_narration }}
                              </h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Breath Rate:</p>
                              <h6>{{ reportToView.breath_rate }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Breath Rate Narration:</p>
                              <h6>{{ reportToView.breath_rate_narration }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">CNS:</p>
                              <h6>{{ reportToView.cns }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Drugs to be Purchased:</p>
                              <h6>{{ reportToView.drugs_to_be_purchased }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Ex_abdomen:</p>
                              <h6>{{ reportToView.ex_abdomen }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Ex_cardiovascular:</p>
                              <h6>{{ reportToView.ex_cardiovascular }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Ex_cns:</p>
                              <h6>{{ reportToView.ex_cns }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Ex_genitourinary:</p>
                              <h6>{{ reportToView.ex_genitourinary }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Ex_musculoskeletal:</p>
                              <h6>{{ reportToView.ex_musculoskeletal }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Ex_respiratory:</p>
                              <h6>{{ reportToView.ex_respiratory }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">
                                Examination Systems gns:
                              </p>
                              <h6>
                                {{ reportToView.examination_systems_gns }}
                              </h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">General Examination:</p>
                              <h6>{{ reportToView.general_examination }}</h6>
                            </li>
                          </ul>
                        </div>

                        <div class="col-12 col-md-6">
                          <ul class="list-group">
                            <li class="list-group-item">
                              <p class="mg-b-0 small">Genitourinary:</p>
                              <h6>{{ reportToView.genitourinary }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Investigation:</p>
                              <h6>{{ reportToView.investigation }}</h6>
                            </li>
                            <li class="list-group-item">
                              <p class="mg-b-0 small">Musculoskeletal:</p>
                              <h6>{{ reportToView.musculoskeletal }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Next Visit:</p>
                              <h6
                                v-if="
                                  reportToView.next_visit == 'null' ||
                                  reportToView.next_visit == ''
                                "
                              ></h6>
                              <h6 v-else>
                                {{ formatFancyDate(reportToView.next_visit) }}
                              </h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Prescription:</p>
                              <h6>{{ reportToView.prescription }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Presenting Complaint:</p>
                              <h6>{{ reportToView.presenting_complaint }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">
                                Presenting Complaint History:
                              </p>
                              <h6>
                                {{ reportToView.presenting_complaint_history }}
                              </h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Pulse:</p>
                              <h6>{{ reportToView.pulse }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Pulse Rate Narration:</p>
                              <h6>{{ reportToView.pulse_rate_narration }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Report:</p>
                              <h6>{{ reportToView.report }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Respiratory:</p>
                              <h6>{{ reportToView.respiratory }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Review Systems gcs:</p>
                              <h6>{{ reportToView.review_systems_gcs }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Specialist Required:</p>
                              <h6>{{ reportToView.specialist_required }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Summary:</p>
                              <h6>
                                <span v-html="reportToView.summary"></span>
                              </h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Temperature:</p>
                              <h6>{{ reportToView.temperature }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Temperature Narration:</p>
                              <h6>{{ reportToView.temperature_narration }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">What Specialist:</p>
                              <h6>{{ reportToView.what_specialist }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Why Investigation:</p>
                              <h6>{{ reportToView.why_investigation }}</h6>
                            </li>

                            <li class="list-group-item">
                              <p class="mg-b-0 small">Why Prescription:</p>
                              <h6>{{ reportToView.why_prescription }}</h6>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-info mr-auto" @click.prevent="PrintReport()">
              Print
            </button>
            <button class="btn btn-primary ml-auto" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Report Modal -->
    <div
      class="modal fade"
      id="editReportModal"
      role="dialog"
      aria-labelledby="editReportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="mr-auto">
              <small class="modal-title" id="editReportModalLabel"
                >Visit Report for Elderly {{ reportToEdit.elderly_name }} on
                {{ formatFancyDate(reportToEdit.appointment_date) }}</small
              >
            </div>
            <div class="ml-auto">
              <span
                class="shadow-none btn btn-success btn-sm"
                v-if="reportToEdit.status == 0"
                >Completed</span
              >
              <span
                class="shadow-none btn btn-dark btn-sm"
                v-if="reportToEdit.status == 1"
                >Not Checked In</span
              >
              <span
                class="shadow-none btn btn-warning btn-sm"
                v-if="reportToEdit.status == 2"
                >Doctor Rescheduled</span
              >
              <span
                class="shadow-none btn btn-primary btn-sm"
                v-if="reportToEdit.status == 3"
                >Patient Rescheduled</span
              >
              <span
                class="shadow-none btn btn-success btn-sm"
                v-if="reportToEdit.status == 4"
                >Completed(couple)</span
              >
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-12 layout-spacing">
                <div class="statbox widget box box-shadow">
                  <div class="widget-content widget-content-area">
                    <form>
                      <div class="row">
                        <div class="form-group mb-4 col-12">
                          <label class="text-dark" for="report">Report</label>
                          <textarea
                            class="form-control"
                            id="report"
                            v-model="reportToEdit.report"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-6">
                          <label class="text-dark" for="elderly_remark"
                            >Elderly Remark</label
                          >
                          <textarea
                            class="form-control"
                            id="elderly_remark"
                            v-model="reportToEdit.elderly_remark"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-6">
                          <label class="text-dark" for="sponsor_remark"
                            >Sponsor Remark</label
                          >
                          <textarea
                            v-model="reportToEdit.sponsor_remark"
                            class="form-control"
                            id="sponsor_remark"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-6">
                          <label class="text-dark" for="med_services_remark"
                            >Med Services Remark</label
                          >
                          <textarea
                            v-model="reportToEdit.med_services_remark"
                            class="form-control"
                            id="med_services_remark"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-6">
                          <label class="text-dark" for="management_remark"
                            >Management Remark</label
                          >
                          <textarea
                            v-model="reportToEdit.management_remark"
                            class="form-control"
                            id="management_remark"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 text-center">
                          <p>Visit Cycle Completed</p>
                          <label
                            class="switch s-icons s-outline s-outline-success mb-2 mr-2"
                          >
                            <input
                              type="checkbox"
                              v-model="reportToEdit.visit_cycle"
                              checked=""
                            />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark ml-auto" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
            <button
              type="submit"
              class="mt-4 mb-4 btn btn-primary"
              @click.prevent="handleEditReport()"
              :disabled="loadingEditUser"
            >
              <b-spinner small v-if="loadingEditUser"></b-spinner>
              <span v-else>Save Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Edit Patient Remark Modal -->
    <div
      class="modal fade"
      id="editPatientRemarkModal"
      role="dialog"
      aria-labelledby="editPatientRemarkModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="mr-auto">
              <small class="modal-title" id="editPatientRemarkModalLabel"
                >Remarks for {{ remarkToEdit.firstname }}
                {{ remarkToEdit.lastname }}</small
              >
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-12 layout-spacing">
                <div class="statbox widget box box-shadow">
                  <div class="widget-content widget-content-area">
                    <form>
                      <div class="row">
                        <div class="col-12 mt-5">
                          <h3
                            class="w-50"
                            style="
                              border-bottom: 1px solid green;
                              border-width: 50%;
                            "
                          >
                            Client Services
                          </h3>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-4">
                          <label
                            class="text-dark"
                            for="last_report_client_services"
                            >Last Remark</label
                          >
                          <textarea
                            v-model="remarkToEdit.last_report_client_services"
                            class="form-control"
                            id="last_report_client_services"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-4">
                          <label
                            class="text-dark"
                            for="elderly_response_client_services"
                            >Elderly Response</label
                          >
                          <textarea
                            v-model="
                              remarkToEdit.elderly_response_client_services
                            "
                            class="form-control"
                            id="elderly_response_client_services"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-4">
                          <label
                            class="text-dark"
                            for="action_to_be_taken_client_services"
                            >Action to be taken</label
                          >
                          <textarea
                            v-model="
                              remarkToEdit.action_to_be_taken_client_services
                            "
                            class="form-control"
                            id="action_to_be_taken_client_services"
                            rows="2"
                          ></textarea>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12 mt-5">
                          <h3
                            class="w-50"
                            style="
                              border-bottom: 1px solid green;
                              border-width: 50%;
                            "
                          >
                            Medical Services
                          </h3>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-4">
                          <label
                            class="text-dark"
                            for="last_report_med_services"
                            >Last Remark</label
                          >
                          <textarea
                            v-model="remarkToEdit.last_report_med_services"
                            class="form-control"
                            id="last_report_med_services"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-4">
                          <label
                            class="text-dark"
                            for="elderly_response_med_services"
                            >Elderly Response</label
                          >
                          <textarea
                            v-model="remarkToEdit.elderly_response_med_services"
                            class="form-control"
                            id="elderly_response_med_services"
                            rows="2"
                          ></textarea>
                        </div>

                        <div class="form-group mb-4 col-12 col-lg-4">
                          <label
                            class="text-dark"
                            for="action_to_be_taken_med_services"
                            >Action to be taken</label
                          >
                          <textarea
                            v-model="
                              remarkToEdit.action_to_be_taken_med_services
                            "
                            class="form-control"
                            id="action_to_be_taken_med_services"
                            rows="2"
                          ></textarea>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark ml-auto" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
            <button
              type="submit"
              class="mt-4 mb-4 btn btn-primary"
              @click.prevent="handleEditClientRemark()"
              :disabled="loadingEditRemark"
            >
              <b-spinner small v-if="loadingEditRemark"></b-spinner>
              <span v-else>Save Changes</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Remind doctor about appointment -->
    <div
      class="modal fade"
      id="resendAppointmentReminderModal"
      role="dialog"
      aria-labelledby="resendAppointmentReminderModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <div class="mr-auto">
              <small
                class="modal-title"
                id="resendAppointmentReminderModalLabel"
                >Resend Appointment Reminder</small
              >
            </div>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-12 layout-spacing">
                <div class="statbox widget box box-shadow">
                  <div
                    class="widget-content widget-content-area text-center"
                    v-if="patientToRemind != null"
                  >
                    <p class="text-dark font-weight-bolder">
                      Please confirm you want to resend an Appointment Reminder
                      for {{ patientToRemind.firstname }}
                      {{ patientToRemind.lastname }} to the patient's doctor Dr.
                      {{ patientToRemind.doctor.firstname || "" }}
                      {{ patientToRemind.doctor.lastname || "" }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-dark ml-auto" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Close
            </button>
            <button
              type="submit"
              @click.prevent="handleAppointmentResend()"
              class="mt-4 mb-4 btn btn-primary"
              :disabled="loadingAppointmentResend"
            >
              <b-spinner small v-if="loadingAppointmentResend"></b-spinner>
              <span v-else>Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { gerohttp } from "@/utils/gerocarehttp";
import { endpoints, geroendpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";
import { DatePickerConfig } from "@/models/datepicker.js";
import $ from "jquery";
var moment = require("moment");

export default {
  name: "CorporateClientMembers",

  components: {},

  computed: {
    ...mapGetters(["url", "gcurl"]),
  },

  watch: {
    seriesPointer: {
      immediate: true,
      handler() {
        if (this.seriesPointer == 10) {
          this.disablePrevSeriesItemButton = true;
        } else {
          this.disablePrevSeriesItemButton = false;
        }

        if (this.seriesPointer > this.allSeries.length) {
          this.disableNextSeriesItemButton = true;
        } else {
          this.disableNextSeriesItemButton = false;
        }
      },
    },
  },

  data() {
    return {
      id: null,

      //first tab
      loading: true,
      client: [],
      users: [],
      clientMemberToShow: [],
      totalVisits: 0,

      //second tab
      patients: {},
      remarkToEdit: {},
      loadingEditRemark: false,
      loadingPatients: true,

      visitSearch: "thisweek",
      filteredUpcomings: [],
      loadingUpcoming: false,
      showCustomPicker: false,
      date: null,
      config: new DatePickerConfig(),

      patientToRemind: null,
      loadingAppointmentResend: false,

      //third tab
      loadingVisitReports: true,
      loadingEditUser: false,
      reports: [],
      reportToView: {},
      reportToEdit: {},

      //fourth tab
      loadingSeries: true,
      allSeries: [],
      seriesPointer: 0,
      disablePrevSeriesItemButton: false,
      disableNextSeriesItemButton: false,

      //chart
      series: [],
      chartOptions: {
        chart: {
          height: "9000px",
          type: "heatmap",
        },
        plotOptions: {
          heatmap: {
            radius: 0,
            useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: -1,
                  to: 50,
                  name: "no visit",
                  color: "#ffffff",
                },
                {
                  from: 99,
                  to: 101,
                  name: "visited",
                  color: "#ffffff",
                },
                {
                  from: 150,
                  to: 250,
                  name: "visit not done",
                  color: "#ffffff",
                },
              ],
            },
          },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 4,
          position: "front",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          y: {
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w } // eslint-disable-line
            ) {
              if (value == 0) {
                return "no visit";
              } else if (value == 200) {
                return "visit not done";
              } else {
                return "visited";
              }
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val, opts) { // eslint-disable-line
            if (val == 0) {
              return "";
            } else if (val == 200) {
              return "?";
            } else {
              return "x";
            }
          },
          style: {
            fontSize: "19px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: ["#000000"],
          },
        },
        stroke: {
          width: 1,
        },
      },
    };
  },

  mounted() {
    this.id = this.$route.params.id;
    this.reports = [];
    this.fetchData();
  },

  methods: {
    initTable() {
      $("#corporate_members").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      // dp.columns([10, 11, 12, 13, 14, 15]).visible(false);
    },

    initVisitReportTable() {
      var dt = $("#corporatemonthtlyvisitreports").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([4, 6, 9, 10, 11, 12, 13, 14]).visible(false);
    },

    initPatientsTable() {
      var dy = $("#corporate_members_patients").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dy.columns([1, 2, 3, 4, 10, 11, 12, 13, 14, 15]).visible(false);
    },

    initUpcomingTable() {
      var dy = $("#corporate_members_patients_filtered").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dy.columns([1, 2, 3, 4, 10, 11, 12, 13, 14, 15]).visible(false);
    },

    fetchData() {
      this.loading = true;
      this.loadingSeries = true;
      gerohttp
        .get(geroendpoints.FETCH_CORPORATE_CLIENT_DATA.replace(":id", this.id))
        .then((response) => {
          this.client = response.client;
          this.users = response.client.users;

          var holder = [];
          this.users.forEach((x) => {
            if (x.patients != null && x.patients.length > 0) {
              var data = [];
              x.patients.forEach((y) => {
                data.push(y);
              });
            }
            holder.push(...data);
          });
          this.patients = holder;

          this.loadFirstSeries(response.series);
          this.chartOptions.xaxis.categories = response.labels;
          this.loadingSeries = false;

          this.totalVisits =
            this.client.visits_count + this.client.pending_visits;

          if (this.client.report_ids.length > 0) {
            this.fetchVisitReports();
          }

          if (this.patients.length > 0) {
            this.fetchClientPatientRemarks();
          }

          this.loading = false;

          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    getVisitsCount(patients) {
      if (patients.length > 0) {
        var counter = 0;
        patients.forEach((x) => {
          if (x.visits != null && typeof x.visits != "undefined") {
            counter += x.visits.length;
          }
        });
        return counter;
      }
      return 0;
    },

    showClientInfo(x) {
      console.log(x);
      this.clientMemberToShow = x;
      $("#viewClientModal").modal("show");
    },

    fetchVisitReports() {
      this.loadingVisitReports = true;
      http
        .post(endpoints.FECTH_VISIT_REPORTS_FOR_CORPORATE_CLIENT, {
          ids: this.client.report_ids,
        })
        .then((response) => {
          this.loadingVisitReports = false;

          this.patients.forEach((x) => {
            response.forEach((y) => {
              if (y.patient_id == x.id) {
                y.next_visit = x.next_visit;
              }
            });
          });

          this.reports = response;

          setTimeout(() => {
            this.initVisitReportTable();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    viewReport(report) {
      this.reportToView = report;
      $("#viewReportModal").modal("show");
    },

    editReport(report) {
      this.reportToEdit = report;
      $("#editReportModal").modal("show");
    },

    handleEditReport() {
      this.loadingEditUser = true;
      http
        .put(endpoints.UPDATE_VISIT_REPORT, {
          id: this.reportToEdit.id,
          elderly_name: this.reportToEdit.elderly_name,
          appointment_id: this.reportToEdit.appointment_id,
          report: this.reportToEdit.report,
          elderly_remark: this.reportToEdit.elderly_remark,
          sponsor_remark: this.reportToEdit.sponsor_remark,
          med_services_remark: this.reportToEdit.med_services_remark,
          management_remark: this.reportToEdit.management_remark,
          visit_cycle: this.reportToEdit.visit_cycle,
        })
        .then((response) => {
          this.loadingEditUser = false;
          $("#editReportModal").modal("hide");
          this.$toast.success(response);
          //this.fetchVisitReports()
        })
        .catch((error) => {
          this.loadingEditUser = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    PrintReport() {
      var HTML_Width = $("#viewReportModalContent").width();
      var HTML_Height = $("#viewReportModalContent").height();
      var top_left_margin = 15;
      var PDF_Width = HTML_Width + top_left_margin * 2;
      var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      var canvas_image_width = HTML_Width;
      var canvas_image_height = HTML_Height;

      var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

      html2canvas($("#viewReportModalContent")[0], { // eslint-disable-line
        allowTaint: true,
      }).then(function (canvas) {
        canvas.getContext("2d");

        console.log(canvas.height + "  " + canvas.width);

        var imgData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]); // eslint-disable-line
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          top_left_margin,
          canvas_image_width,
          canvas_image_height
        );

        for (var i = 1; i <= totalPDFPages; i++) {
          pdf.addPage(PDF_Width, PDF_Height);
          pdf.addImage(
            imgData,
            "JPG",
            top_left_margin,
            -(PDF_Height * i) + top_left_margin * 4,
            canvas_image_width,
            canvas_image_height
          );
        }

        pdf.save(`report.pdf`);
      });
    },

    fetchClientPatientRemarks() {
      var ids = [];
      this.loadingPatients = true;
      this.patients.forEach((x) => {
        ids.push(x.id);
      });

      http
        .post(endpoints.FETCH_CORPORATE_CLIENT_PATIENT_REMARKS, {
          ids,
        })
        .then((response) => {
          this.patients.forEach((x) => {
            response.forEach((y) => {
              if (x.id == y.patient_id) {
                x.last_report_client_services =
                  y.last_report_client_services ?? null;
                x.elderly_response_client_services =
                  y.elderly_response_client_services ?? null;
                x.action_to_be_taken_client_services =
                  y.action_to_be_taken_client_services ?? null;

                x.last_report_med_services = y.last_report_med_services ?? null;
                x.elderly_response_med_services =
                  y.elderly_response_med_services ?? null;
                x.action_to_be_taken_med_services =
                  y.action_to_be_taken_med_services ?? null;
              }
            });
          });

          this.loadingPatients = false;

          setTimeout(() => {
            this.initPatientsTable();
            this.sortByTimeRange();
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editPatientRemark(patient) {
      this.remarkToEdit = patient;
      $("#editPatientRemarkModal").modal("show");
    },

    handleEditClientRemark() {
      this.loadingEditRemark = true;
      http
        .put(endpoints.SAVE_CORPORATE_CLIENT_PATIENT_REMARKS, {
          patient_id: this.remarkToEdit.id,
          last_report_client_services:
            this.remarkToEdit.last_report_client_services,
          elderly_response_client_services:
            this.remarkToEdit.elderly_response_client_services,
          action_to_be_taken_client_services:
            this.remarkToEdit.action_to_be_taken_client_services,

          last_report_med_services: this.remarkToEdit.last_report_med_services,
          elderly_response_med_services:
            this.remarkToEdit.elderly_response_med_services,
          action_to_be_taken_med_services:
            this.remarkToEdit.action_to_be_taken_med_services,
        })
        .then((response) => {
          this.loadingEditRemark = false;
          $("#editPatientRemarkModal").modal("hide");
          this.$toast.success(response);
        })
        .catch((error) => {
          this.loadingEditRemark = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    loadFirstSeries(x) {
      this.allSeries = x;
      this.seriesPointer = 10;
      this.series = this.allSeries.slice(0, 10);
    },

    prevSeriesItems() {
      this.seriesPointer -= 10;
      this.series = this.allSeries.slice(
        this.seriesPointer,
        this.seriesPointer + 10
      );
    },

    nextSeriesItems() {
      this.seriesPointer += 10;
      this.series = this.allSeries.slice(
        this.seriesPointer,
        this.seriesPointer + 10
      );
    },

    sortByTimeRange() {
      if (this.visitSearch == "thisweek") {
        this.loadingUpcoming = true;
        this.showCustomPicker = false;

        setTimeout(() => {
          var start = moment().startOf("week");
          var end = moment().endOf("week");
          var data = [];

          this.patients.forEach((x) => {
            if (
              moment(x.next_visit).isBetween(start, end) ||
              moment(x.next_visit).isSame(start) ||
              moment(x.next_visit).isSame(end)
            ) {
              data.push(x);
            }
          });

          console.log(data);
          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 2000);
      } else if (this.visitSearch == "nextweek") {
        this.loadingUpcoming = true;
        this.showCustomPicker = false;

        setTimeout(() => {
          var start = moment().startOf("week").add(7, "d");
          var end = moment().endOf("week").add(7, "d");
          var data = [];

          this.patients.forEach((x) => {
            if (
              moment(x.next_visit).isBetween(start, end) ||
              moment(x.next_visit).isSame(start) ||
              moment(x.next_visit).isSame(end)
            ) {
              data.push(x);
            }
          });

          console.log(data);
          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 2000);
      } else if (this.visitSearch == "lastweek") {
        this.loadingUpcoming = true;
        this.showCustomPicker = false;

        setTimeout(() => {
          var start = moment().startOf("week").subtract(1, "w");
          var end = moment().endOf("week").subtract(1, "w");
          var data = [];

          this.patients.forEach((x) => {
            if (
              moment(x.next_visit).isBetween(start, end) ||
              moment(x.next_visit).isSame(start) ||
              moment(x.next_visit).isSame(end)
            ) {
              data.push(x);
            }
          });

          console.log(data);
          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 2000);
      } else if (this.visitSearch == "thismonth") {
        this.loadingUpcoming = true;
        this.showCustomPicker = false;

        setTimeout(() => {
          var start = moment().startOf("month");
          var end = moment().endOf("month");
          var data = [];

          this.patients.forEach((x) => {
            if (
              moment(x.next_visit).isBetween(start, end) ||
              moment(x.next_visit).isSame(start) ||
              moment(x.next_visit).isSame(end)
            ) {
              data.push(x);
            }
          });

          console.log(data);
          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 3000);
      } else if (this.visitSearch == "nextmonth") {
        this.loadingUpcoming = true;
        this.showCustomPicker = false;

        setTimeout(() => {
          var start = moment().startOf("month").add(1, "M");
          var end = moment().endOf("month").add(1, "M");
          var data = [];

          this.patients.forEach((x) => {
            if (
              moment(x.next_visit).isBetween(start, end) ||
              moment(x.next_visit).isSame(start) ||
              moment(x.next_visit).isSame(end)
            ) {
              data.push(x);
            }
          });

          console.log(data);
          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 3000);
      } else if (this.visitSearch == "lastmonth") {
        this.loadingUpcoming = true;

        setTimeout(() => {
          var start = moment().startOf("month").subtract(30, "d");
          var end = moment().startOf("month");
          var data = [];

          this.patients.forEach((x) => {
            if (
              moment(x.next_visit).isBetween(start, end) ||
              moment(x.next_visit).isSame(start) ||
              moment(x.next_visit).isSame(end)
            ) {
              data.push(x);
            }
          });

          console.log(data);
          this.filteredUpcomings = data;
          this.loadingUpcoming = false;

          setTimeout(() => {
            this.initUpcomingTable();
          }, 1000);
        }, 2000);
      } else {
        this.showCustomPicker = true;
      }
    },

    handlePicker() {
      this.loadingUpcoming = true;
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");

          setTimeout(() => {
            var start = moment(split[0]);
            var end = moment(split[2]);
            var data = [];
            console.log(start, end);

            this.patients.forEach((x) => {
              if (
                moment(x.next_visit).isBetween(start, end) ||
                moment(x.next_visit).isSame(start) ||
                moment(x.next_visit).isSame(end)
              ) {
                data.push(x);
              }
            });

            console.log(data);
            this.filteredUpcomings = data;
            this.loadingUpcoming = false;

            setTimeout(() => {
              console.log("init ");
              this.initUpcomingTable();
            }, 5000);
          }, 2000);
        }
      }, 500);
    },

    resendPatientAppointmentReminder(patient) {
      this.patientToRemind = patient;
      $("#resendAppointmentReminderModal").modal("show");
    },

    handleAppointmentResend() {
      this.loadingAppointmentResend = true;
      gerohttp
        .post(geroendpoints.SEND_APPOINTMENT_REMINDER_FOR_CORPORATE_CLIENT, {
          _key: process.env.VUE_APP_API_SECRET_KEY,
          id: this.patientToRemind.id,
        })
        .then((response) => {
          this.$toast.success(response);
          this.loadingAppointmentResend = false;
          $("#resendAppointmentReminderModal").modal("hide");
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
          this.loadingAppointmentResend = false;
        });
    },
  },
};
</script>

<style
  scoped
  src="@/assets/css/components/tabs-accordian/custom-tabs.css"
></style>
<style scoped src="@/assets/css/tables/table-basic.css"></style>

<style>
.exportPNG,
.exportSVG,
.exportCSV {
  color: black !important;
}
</style>
